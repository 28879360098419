const Navbar = () => {  
  return (
  <nav className="navbar" role="navigation" aria-label="main navigation">
      <div className="navbar-brand">
        <div className="navbar-item">
          <img src="https://imageslsdt.s3.eu-west-3.amazonaws.com/LSDT_logo_definitif.png" alt="logo LSDT composé des initiales" width="112" height="28" />
        </div>
      </div>
    </nav>
  )
}

export default Navbar