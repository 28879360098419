import { url_api, guid } from './config';

import dayjs from 'dayjs'

const fetchToken = async() => {
  try {
    const fetch_access_token = await fetch(`${url_api}/login/landing`, {
      method: 'GET',
      headers: {
        message: `LSDT_LANDING_${dayjs().format('DD/MM/YYYY')}_${guid}`
      },
      mode: 'cors'
    });
    const access_token = await fetch_access_token.text();

    return JSON.parse(access_token);
  }
  catch (err) {
    console.log(err);
  }
}

export default fetchToken