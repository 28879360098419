const Title = () => {
  return (
    <div className="has-text-centered mt-6 mx-4">
      <div className="title">
        <p className="has-text-black is-family-secondary has-text-weight-bold">Bienvenue à</p>
        <p className="has-text-black is-family-secondary has-text-weight-bold mt-2">LA SALLE DU TEMPS</p>
      </div>
      <div className="columns is-vcentered mt-5">
        <div className="column is-half is-offset-3">
          <ul className="content has-text-justified is-size-5 mx-6">
            <li>
              <span className="mr-3 icon is-small has-text-warning">
                <i className="fas fa-star" />
              </span>
                Vous pouvez réserver votre séance GymLib pour LA SALLE DU TEMPS sur le formulaire plus bas.
            </li>
            <li>
              <span className="mr-3 icon is-small has-text-warning">
                <i className="fas fa-star"></i>
              </span>
              A votre arrivée à LA SALLE DU TEMPS, le coach vous demandera de valider votre séance sur l'application GymLib. Sans cette étape, vous ne pourrez pas assister à votre séance.
            </li>
            <li className="is-large">
              <span className="mr-3 icon is-small has-text-warning">
                <i className="fas fa-star"></i>
              </span>
              Pensez à bien enregistrer votre séance dans votre agenda. Nous ne vous enverrons pas de rappel.
            </li>
            <li className="is-large">
              <span className="mr-3 icon is-small has-text-warning">
                <i className="fas fa-star"></i>
              </span>
              Soyez présent 5 minutes avant le début de votre séance !
            </li>
            <li className="is-large">
              <span className="mr-3 icon is-small has-text-warning">
                <i className="fas fa-star"></i>
              </span>
              Le coach est là pour vous ! N'hésitez pas à lui parler de vos objectifs.
            </li>
            <li className="is-large">
              <span className="mr-3 icon is-small has-text-warning">
                <i className="fas fa-star"></i>
              </span>
              Venez avec votre bonne humeur et profitez à fond de votre entrainement.
            </li>
            <li className="is-large">
              <span className="mr-3 icon is-small has-text-warning">
                <i className="fas fa-star"></i>
              </span>
              Un problème ? Une question ? Vous ne pouvez pas assister à votre séance ? Appelez-nous : 01 47 72 57 20.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Title;
