import { useEffect, useState } from 'react';

import logo from './logo.svg';
import './App.css';
import Navbar from './components/Navbar';
import fetchToken from './utils/fetchToken';
import fetchGymActivities from './utils/fetchGymActivities';
import BookingForm from './components/Booking_form';
import Footer from './components/Footer';
import Title from './components/Title';

function App() {
  const [accessToken, setAccessToken] = useState(null)
  const [gymActivities, setGymActivities] = useState(null)

  useEffect(() => {
    (async () => {
      const token = await fetchToken();

      setAccessToken(token);
      const gym_activities = await fetchGymActivities(token)

      setGymActivities(gym_activities)
    })()
  }, [])

// recuperer les info => 
// horaires (en fonction de l'activité)


  return (
    // <div className="App">
    <>
      <Navbar />
      <Title />
      <br />
      <BookingForm gymActivities={gymActivities} accessToken={accessToken}/>
      <Footer />
      {/* <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header> */}
    </>
  );
}

export default App;
