import { useState , useEffect } from 'react'

import fetchSessions from '../utils/fetchSessions'
import recordAppointment from '../utils/recordAppointment';

import DatePicker from 'react-datepicker'
import { registerLocale } from  "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css'
import dayjs from 'dayjs'
import fr from 'date-fns/locale/fr';

registerLocale('fr', fr)

const BookingForm = (props) => {
  const [startDate, setStartDate] = useState(new Date())
  const [activity, setActivity] = useState('')
  const [sessions, setSessions] = useState([])
  const [session, setSession] = useState('')
  const [isModalActive, activeModal] = useState(false)
  const [isSuccessModalActive, activeSuccessModal] = useState(false)
  const [isErrorModalActive, activeErrorModal] = useState(false)
  const [successModalMessage, setSuccessModalMessage] = useState('')
  const [errorModalMessage ,setErrorModalMessage] = useState('')
 
  useEffect(() => {
    if (activity !== '') {
      (async () => {
        const new_sessions = await fetchSessions(props.accessToken, startDate, activity)

        console.log('new_sessions => ', new_sessions)
        setSessions(new_sessions)
      })()
    }
  }, [activity, startDate])

  const clearForm = () => {
    document.getElementById('booking_form').reset()
  }

  const activities = props.gymActivities ?
    props.gymActivities.map((activity, index) => {
      if (activity.display_activity) {
        return <option id={activity.id} key={index}>{activity.activity}</option>
      }
      return null
    }).filter(activity => activity !== null)
  : null
  const new_sessions = sessions ? sessions.map((session, index) => {
    return <option key={index}>{session}</option>
  }) : sessions

  return (
    <div>
      <div className="columns is-vcentered">
        <div className="column is-half is-offset-3">
        {/* <div className="column is-one-third is-offset-one-third"> */}

          <div className="title has-text-centered mx-3 mt-5">
            <p className="has-text-black is-family-secondary has-text-weight-bold">Remplissez les informations pour valider votre séance.</p>
          </div>
          {/* <div className="title has-text-centered">
            <h1 className="has-text-black is-family-secondary has-text-weight-bold">Teste nous ! Première séance offerte pour la rentrée.</h1>
          </div> */}
          {/* <h2 className="subtitle is-4 has-family-primary has-text-centered has-text-black has-text-weight-bold">Teste nous ! Première séance offerte pour la rentrée.</h2> */}
          <div className="box mx-6 my-6">
            <form className="fields" id="booking_form">
            <label className="label">Choisissez la date</label>
            <DatePicker
              // selected={Date.parse(this.state.startDate)}
              // onChange={date => this.selectDate(date)}
              withPortal
              // inline
              // onClickOutside={this.closeDatePicker}
              minDate={new Date()}
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              maxDate={Date.parse(dayjs().add(1, 'months'))}
              locale="fr"
              // dateFormat="yyyy/MM/dd"
              dateFormat="dd/MM/yyyy"
            />
            <br />
            <label className="label">Choisissez votre activité</label>
            <div className="control has-icons-left has-icons-right">
              <div className="select is-normal">
                <select name="activity" required onChange={(e) => {
                  // console.log('activity => ', activity)
                  setActivity(e.target.value)
                  }}>
                  <option></option>
                  {activities}
                </select>
              </div>
              <div className="icon is-small is-left">
                <i className="fas fa-dumbbell"></i>
              </div>
            </div>
            <br/>
            <label className="label">Choisissez parmis nos créneaux disponibles :</label>
              <div className="control has-icons-left has-icon-right">
                <div className="select is-normal mb-4">
                  {
                    activity !== ''
                    ?
                    <select name="hour" required onChange={(e) => setSession(e.target.value)}>
                      <option></option>
                      {new_sessions}
                    </select>
                    :
                    <select name="hour" required disabled>
                      <option></option>
                    </select>
                  }
                </div>
                <span className="icon is-small is-left">
                  <i className="fas fa-clock"></i>
                </span>
              </div>
              <br />
              <div className="control">
                {
                  session !== '' && activity !== ''
                  ?
                  <button 
                    type='submit'
                    className="button is-success is-fullwidth"
                    onClick={(e) => {
                      e.preventDefault()
                      activeModal(true)
                    }}>Valider</button>
                  :
                  <button type='submit' className="button is-success is-fullwidth" disabled>Valider</button>
                }
              </div>
                {
                  sessions.length === 0 && activity !== ''
                    ?
                    <div className="has-text-danger has-text-weight-bold has-text-centered">Nous n'avons plus de créneaux disponibles pour cette date</div>
                    :
                    <div></div>
                }
            </form>
          </div>
        </div>
      </div>
      <div className={isModalActive ? 'modal is-active' : 'modal'}>
        <div className="modal-background" />
          <div className="modal-card px-6">
            <header className="modal-card-head">
              <div className="container">
                <p className="modal-card-title">Récapitulatif de votre séance</p>
              </div>
              <button className="delete" aria-label="close" onClick={() => activeModal(false)} />
            </header>
            {/* onClick={() => activeModal(false)} */}
            <section className="modal-card-body">
              <div className="content">
                <ul>
                  <li className="subtitle is-6">
                    {`Date: ${dayjs(startDate).format('DD/MM/YYYY')}`}
                  </li>
                  <li className="subtitle is-6">
                    {`Activité: ${activity}`}
                  </li>
                  <li className="subtitle is-6">
                    {`Heure: ${session}`}
                  </li>
                </ul>
              </div>
            </section>
            <footer className="modal-card-foot">
              <button className="button is-success is-fullwidth" onClick={async () => {
                const record_appointment = await recordAppointment(props.accessToken, startDate, activity, session)

                if (record_appointment === 'Votre séance a correctement été enregistrée !') {
                  setSuccessModalMessage(record_appointment)
                  activeModal(false)
                  activeSuccessModal(true)
                }
                else {
                  setErrorModalMessage(`Une erreur est survenue pendant l'enregistrement de votre séance. Merci de renouveller l'enregistrement ultérieurement`)
                  activeModal(false)
                  activeErrorModal(true)
                }
              }}>Confirmer</button>
            </footer>
          </div>
        </div>

        <div className={isSuccessModalActive ? 'modal is-active' : 'modal'}>
          <div className="modal-background" />
          <div className="modal-card px-6">
            <header className="modal-card-head has-background-success has-text-white">
              <p className="modal-card-title has-text-white">Félicitations</p>
              <button className="delete" aria-label="close" onClick={() => activeSuccessModal(false)} />
            </header>
            <section className="modal-card-body">
              <h4 className="subtitle ">{successModalMessage}</h4>
              <br />
              <div className="has-text-centered">
                <button className="button is-fullwidth" onClick={() => {
                  activeSuccessModal(false)
                  setSession('')
                  setActivity('')
                  setStartDate(new Date())
                  clearForm()
                  }}>Ok</button>
              </div>
            </section>
          </div>
        </div>

        <div className={isErrorModalActive ? 'modal is-active' : 'modal'}>
            <div className="modal-background" />
            <div className="modal-card px-6">
              <header className="modal-card-head has-background-danger has-text-white">
                <p className="modal-card-title has-text-white">Erreur</p>
                <button className="delete" aria-label="close" onClick={() => activeErrorModal(false)} />
              </header>
              <section className="modal-card-body">
                <h4 className="sutitle">{errorModalMessage}</h4>
                <br />
                <div className="has-text-centered">
                  <button className="button is-fullwidth" onClick={() => activeErrorModal(false)}>Ok</button>
                </div>
              </section>
            </div>
          </div>

    </div>
  )
}

export default BookingForm;
// faire une verif coté back-end pour s'assurer que la séance n'a pas été prise entre temps ...