import { url_api, gym_id } from "./config";

const fetchGymActivities = async (token) => {
  const fetch_gym_activities = await fetch(`${url_api}/gyms/partners/gyms/${gym_id}`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    mode: 'cors'
  });
  const gym_data = await fetch_gym_activities.json();
  
  return gym_data;
}

export default fetchGymActivities;