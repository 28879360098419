const Footer = () => {
  return (
    <footer className="footer">
      <div className="columns">
        <div className="content has-text-centered column">
          <p>
            <img src="https://imageslsdt.s3.eu-west-3.amazonaws.com/LSDT_logo_definitif.png" width="112" height="28" alt="logo avec les initiales LSDT"/>
          </p>
          <p>
            <strong>© 20201 LA SALLE DU TEMPS</strong>
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
