import { url_api, gym_id } from "./config";

import dayjs from "dayjs";

const fetchSessions = async (token, date, activity) => {

  console.log(`dayjs(date).format('DD-MM-YYYY')`, dayjs(date).format('DD-MM-YYYY'))
  const fetch_sessions = await fetch(`${url_api}/appointments/partners/gyms/${gym_id}/dates/${dayjs(date).format('DD-MM-YYYY')}/activities/${activity}`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    mode: 'cors'
  });
  const sessions = await fetch_sessions.json()
  
  console.log("sessions ::::", sessions )
  return sessions;
}

export default fetchSessions