import { url_api, gym_id } from "./config";

import dayjs from "dayjs";

const recordAppointment = async (token, date, activity, session) => {
  const record_subscription = await fetch(`${url_api}/subscriptions/partners/gymlib/gyms/${gym_id}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify({
      date: dayjs(date).format('DD-MM-YYYY'),
      activity: activity,
      hour: session
    }),
    mode: 'cors'
  })

  const server_response = await record_subscription.text()

  return server_response
}

export default recordAppointment